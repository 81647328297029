import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SlidersSVG } from "../../assets/newImages/icons/sliders-v.svg";
import AsideMenu from "../../components/AsideMenu/AsideMenu";
import { Filtros } from "../../components/Filtros/Filtros";
import { FiltrosData } from "../../components/FiltrosData/FiltrosData";
import { FooterPaginas } from "../../components/FooterPaginas/FooterPaginas";
import { Input } from "../../components/Input";
import Paginacao from "../../components/Paginacao";
import { Select } from "../../components/Select";
import { SkeletonTableCestas } from "../../components/Skeletons/SkeletonTableCestas/SkeletonTableCestas";
import Table from "../../components/Table/Table";
import { Toggle } from "../../components/Toggle/Toggle";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { CatalogosApi } from "../../hooks/catalogos";
import { CestasAbandonadasApi } from "../../hooks/cestasAbandonadasApi";
import { PerfilApi } from "../../hooks/perfilApi";
import { cleanToFilter, copy } from "../../services/Functions";
import {
  cepMask,
  cnpjMask,
  cpfMask,
  dateMask,
  phoneMask
} from "../../services/Masks";
import "./styles.ts";
import { ContainerCestasAbandonadas } from "./styles";
import { CestaAbandonada } from "../../types/CestaAbandonada";
import { SelectPedidos } from "../../components/Select/SelectPedidos/index";
import Swal from "sweetalert2";
import { Button } from "../../components/Button";
import { useReactToPrint } from "react-to-print";
import CestasImpressao from "./CestasImpressao/CestasImpressao";
import LoadingComFundo from "../../components/Loading/LoadingComFundo/LoadingComFundo.tsx";

type camposListagem = 
"id_cesta_abandonada"
| "descricao"
| "data_atualizacao"
| "observacao"
| "moeda"
| "valor_total"
| "nome_razao_social"
| "cpf_cnpj"
| "tabela_preco"
| "inscricao_estadual"
| "email"
| "telefone"
| "data_nascimento"
| "usuario"
| "estado"
| "cep"
| "cidade"
| "bairro"
| "endereco_completo";

const camposPersonalizados: 
  Array<{
    nome: string;
    campo: camposListagem;
    mask?: (value: string) => string;
  }>
  = [
  {
    nome: "Catálogo",
    campo: "descricao",
  },
  {
    nome: "Data de criação",
    campo: "data_atualizacao",
    mask: (value) => dateMask(value),
  },
  {
    nome: "Observação",
    campo: "observacao",
  },
  {
    nome: "Cifrão",
    campo: "moeda",
  },
  {
    nome: "Valor",
    campo: "valor_total",
    mask: (value) => {
      return Number(value).toFixed(2).replace(".", ",");
    },
  },
  {
    nome: "Cliente",
    campo: "nome_razao_social",
  },
  {
    nome: "CPF/CNPJ",
    campo: "cpf_cnpj",
    mask: (value: string) => {
      return value.length <= 11 ? cpfMask(value) : cnpjMask(value);
    },
  },
  { nome: "Tabela de preço", campo: "tabela_preco" },
  {
    nome: "Inscrição Estadual",
    campo: "inscricao_estadual",
  },
  { nome: "E-mail", campo: "email" },
  {
    nome: "Telefone",
    campo: "telefone",
    mask: phoneMask,
  },
  {
    nome: "Data de nascimento",
    campo: "data_nascimento",
    mask: (value) => dateMask(value),
  },
  { nome: "Vendedor", campo: "usuario" },
  { nome: "UF", campo: "estado" },
  { nome: "CEP", campo: "cep", mask: cepMask },
  { nome: "Cidade", campo: "cidade" },
  { nome: "Bairro", campo: "bairro" },
  {
    nome: "Endereço, nº e complemento",
    campo: "endereco_completo",
  },
];

interface CestasAbandonadasProps {}


const cores = {
  // cores conforme o id da situação no banco
  1: "#4de889", //em aberto
  2: "#e7be00", //em contato
  3: "#ffbe9d", //nao convertido
};

const CestasAbandonadas: React.FC<CestasAbandonadasProps> = ({}) => {
  const navigate = useNavigate();
  const [asideMenu, setAsideMenu] = useState(false);
  const [loading, setLoading] = useState(false);

  const impressaoRef = useRef<any>();

  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  const { theme } = useTheme();

  //api
  const perfilApi = PerfilApi();
  const catalogosApi = CatalogosApi();
  const cestasAbandonadasApi = CestasAbandonadasApi();

  //paginacao
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itensPorPagina, setItensPorPagina] = useState(50);

  const [cestasAbandonadas, setCestasAbandonadas] = useState<[]>([]);
  const [cestasAbandonadasFiltradas, setCestasAbandonadasFiltradas] = useState<
    Array<any> | undefined
  >(undefined);
  const [cestasSelecionados, setcestasSelecionados] = useState<
    CestaAbandonada[]
  >([]);
  const [situacoesFiltradas, setSituacoesFiltradas] = useState<
    Array<{situacao?: number; value: number; name: string; quantidade?: number}>
  | undefined
  >(undefined);
  const [situacoes, setSituacoes] = useState<
    Array<{ value: number; name: string; quantidade?: number }>
  >([]);
  const [optionsUsuarios, setOptionsUsuarios] = useState<
    { name: string; value: string }[]
  >([]);
  const [optionsCatalogos, setOptionsCatalogos] = useState<
    { name: string; value: string }[]
  >([]);

  const [camposAtivos, setCamposAtivos] = 
    useState<camposListagem[]>(
      localStorage.getItem("camposAtivosCestas") ?
        JSON.parse(localStorage.getItem("camposAtivosCestas")!) as camposListagem[] :
        camposPersonalizados.map(campo => campo.campo));
  const [camposAtivosAux, setCamposAtivosAux] = useState<camposListagem[]>(copy(camposAtivos));

  //filtros
  const [filtroPesquisa, setFiltroPesquisa] = useState("");
  const [situacaoAtiva, setSituacaoAtiva] = useState("1");
  const [filtroUsuario, setFiltroUsuario] = useState("");
  const [filtroCatalogo, setFiltroCatalogo] = useState("");
  const [filtroClientesIdentificados, setFiltroClientesIdentificados] =
    useState("true");
  const [dataInicio, setDataInicio] = useState(
    `${moment().subtract(30, "days").format("YYYY-MM-DD")} 00:00:00`
  );
  const [dataFim, setDataFim] = useState(
    `${moment().format("YYYY-MM-DD")} 23:59:59`
  );

  //auxiliares
  const [filtroUsuarioAux, setFiltroUsuarioAux] = useState("");
  const [filtroCatalogoAux, setFiltroCatalogoAux] = useState("");
  const [filtroClientesIdentificadosAux, setFiltroClientesIdentificadosAux] =
    useState("true");
  const [filtroPesquisaAux, setFiltroPesquisaAux] = useState("");
  const [cestasImpressao, setCestasImpressao] = useState<[]>([]);

  //table
  const [tableColumns, setTableColumns] = useState<
    Array<{ key: string; label: string }>
  >([]);

  const init = async (
    dataInicio: string,
    dataFim: string,
    filtroUsuario: string,
    filtroCatalogo: string,
    filtroClientesIdentificados: string,
    camposAtivos: camposListagem[]
  ) => {
    setLoadingSkeleton(true)
    const [usuarios, catalogos, cestasAbandonadas, situacoes] = await Promise.all([
      perfilApi.usuarios(),
      catalogosApi.getCatalogosFiltroPedidos(),
      cestasAbandonadasApi.getCestasAbandonadas(
        camposAtivos,
        { inicio: dataInicio, fim: dataFim },
        {
          usuario: filtroUsuario,
          catalogo: filtroCatalogo,
          clientes_identificados: filtroClientesIdentificados === "true" ? 1 : 0,
        }
      ),
      cestasAbandonadasApi.getSituacoes(),
    ]);

    const cestasFiltradas = cestasAbandonadas.filter(
      (cesta: any) => cesta.situacao === Number(situacaoAtiva)
    );

    setCestasAbandonadas(cestasAbandonadas);
    setCestasAbandonadasFiltradas(cestasFiltradas);
    
    setSituacoes(
      situacoes.map((situacao: any) => ({
        name: situacao.descricao,
        value: situacao.id.toString(),
        quantidade: cestasAbandonadas.filter(
          (cesta: any) => cesta.situacao === situacao.id
        ).length,
      }))
    );
    
    setOptionsUsuarios([
      { name: "Todos os usuários", value: "" },
      ...usuarios.map((usuario: any) => ({
        name: usuario.nome,
        value: usuario.nome,
      })),
    ]);

    setOptionsCatalogos([
      { name: "Todos os catálogos", value: "" },
      ...catalogos.map((catalogo: any) => ({
        name: catalogo.descricao,
        value: catalogo.id,
      })),
    ]);

    setTableColumns(
      camposPersonalizados
        .filter((campo) => camposAtivos.includes(campo.campo))
        .map((campo) => ({
          key: campo.campo,
          label: campo.nome,
          mask: campo.mask,
        }))
    );

    setTotalPages(Math.ceil(cestasFiltradas.length / itensPorPagina));
    setCurrentPage(1);

    let situacaoAtivaMomento = localStorage.getItem("situacao") || "1";
    
    handleChangeSituacao(situacaoAtivaMomento, cestasAbandonadas)
  };

  const handleImprimirCestas = async () => {
    setLoading(true);

    const cestasImpressao = await cestasAbandonadasApi.getCestasImpressao(
      cestasSelecionados.map((cesta: any) => cesta.id_cesta_abandonada)
    );
    setCestasImpressao(cestasImpressao);
  };

  const checkImagesLoaded = (container: any) => {
    //funcao async que verifica se todas as imagens foram carregadas
    return new Promise((resolve, reject) => {
      let images = container.querySelectorAll("img");
      let loadedImages = 0;

      if (images.length === 0) {
        resolve(true);
      }

      const onImageLoaded = () => {
        loadedImages++;
        if (loadedImages === images.length) {
          resolve(true);
        }
      };

      images.forEach(image => {
        if (image.complete && image.naturalHeight !== 0) {
          onImageLoaded();
        } else {
          image.addEventListener('load', onImageLoaded, { once: true });
        }
      });
    });
  }

  const handlePrint = useReactToPrint({
    content: () => impressaoRef.current,
    onBeforeGetContent: async () => {
      await checkImagesLoaded(impressaoRef.current);
      //mesmo com a funcao acima ainda tem imagens que nao carregavam, entao coloquei um timeout 
      //e funcionou, timeout de 0 segundos supremacy
      await new Promise(resolve => setTimeout(resolve, 0));
    },
    onBeforePrint: () => {
    },
    onPrintError: () => {
      setLoading(false);
      setCestasImpressao([]);
    },
    onAfterPrint: () => {
      setLoading(false);
      setCestasImpressao([]);
    },
  });

  useEffect(() => {
    if (cestasImpressao && cestasImpressao.length) {
      handlePrint();
    }
  }, [cestasImpressao]); // Dependencies array

  const filtrarData = (data: {
    inicio: string;
    fim: string;
    placeholder: string;
  }) => {
    setDataInicio(data.inicio);
    setDataFim(data.fim);
    localStorage.setItem("pedidos-dataPlaceholder", data.placeholder);

    init(
      data.inicio,
      data.fim,
      filtroUsuario,
      filtroCatalogo,
      filtroClientesIdentificados,
      camposAtivos
    );
  };

  const situacaoSelecionada = situacoes.find((situacao) => situacao.name)?.value?.toString();

  const onAplicarFiltro = (
    filtroUsuario: string,
    filtroCatalogo: string,
    filtroClientesIdentificados: string
  ) => {
    setFiltroUsuario(filtroUsuario);
    setFiltroCatalogo(filtroCatalogo);
    setFiltroClientesIdentificados(filtroClientesIdentificados);

    init(
      dataInicio,
      dataFim,
      filtroUsuario,
      filtroCatalogo,
      filtroClientesIdentificados,
      camposAtivos
    );
  };
  const handleMoverCestas = async (situacao: string) => {
    if (situacao === situacaoAtiva) return;

    const confirmationOptions = {
          title: "Tem certeza que deseja alterar a situação da(s) sacola(s)?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Alterar sacolas(s)",
          cancelButtonText: "Cancelar",
        };

    const confirmed = await showSwalConfirmation(confirmationOptions);
    if (confirmed) {
      setLoadingSkeleton(true);

      await updateSacolasSituacao(Number.parseInt(situacao));
    }
  };

  const updateSacolasSituacao = async (situacao: number) => {
    const sacolasParaParaAlterar = cestasSelecionados.map(
      (cesta) => cesta.id_cesta_abandonada
    );
    if (
      await cestasAbandonadasApi
        .alterarSituacao(sacolasParaParaAlterar, situacao)
        .catch((err) => {
          init(
            dataInicio,
            dataFim,
            filtroUsuario,
            filtroCatalogo,
            filtroPesquisa,
            camposAtivos
          );
          return false;
        })
    ) {
      init(dataInicio, dataFim, filtroUsuario, filtroCatalogo, filtroClientesIdentificados, camposAtivos);
      setcestasSelecionados([]);
      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "sacolas(s) alterado(s).",
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };
  
  const showSwalConfirmation = async (options: any) => {
    const result = await Swal.fire(options);
    return result.isConfirmed;
  };

  const handleDetalhesCesta = (cesta: { [key in camposListagem]: any }) => {
    const encodedNome = cesta.nome_razao_social ? encodeURIComponent(cesta.nome_razao_social) : "";

  
    navigate(
      `/painel/sacolas-abandonadas/sacola-abandonada/${
        cesta.id_cesta_abandonada
      }${
        "/" + encodedNome
      }`
    );
  };
  
  const handlePaginacao = (page: number) => {
    setCurrentPage(page);
  };

  const handleAplicarCampos = async () => {
    localStorage.setItem("camposAtivosCestas", JSON.stringify(camposAtivos));
    init(
      dataInicio,
      dataFim,
      filtroUsuario,
      filtroCatalogo,
      filtroClientesIdentificados,
      camposAtivos
    );
  };

  const handleChangeSituacao = (situacao: string, cestasAbandonadas: CestaAbandonada[]) => {
    if (situacao === situacaoAtiva) return;
    setSituacaoAtiva(situacao);
    setCurrentPage(1);
    setcestasSelecionados([]);
    const novasCestasAbandonadasFiltradas = cestasAbandonadas.filter(
      (cesta: any) => cesta.situacao === Number(situacao)
    );
    setCestasAbandonadasFiltradas(novasCestasAbandonadasFiltradas);
    setTotalPages(Math.ceil(novasCestasAbandonadasFiltradas.length / itensPorPagina));
    localStorage.setItem("situacao", situacao);
  };
  
  
  //filtro de pesquisa
  useEffect(() => {
    if (filtroPesquisa === "") {
      // Restaurar ao estado inicial
      setSituacoesFiltradas(undefined); 
      setTotalPages(Math.ceil(cestasAbandonadas.length / itensPorPagina));
      setCurrentPage(1);
      setLoadingSkeleton(false);
    } else {
      const filtro = cleanToFilter(filtroPesquisa);
      // filtra todos as cestas
      const novasCestasAbandonadasFiltradas: CestaAbandonada[] =
        cestasAbandonadas.filter((cesta) => {
          if (
            Object.values(cesta).some((value) =>
              String(value).toLowerCase().includes(filtro)
            )
          )
            return cesta;
        }) || [];

        if (Object.keys(novasCestasAbandonadasFiltradas).length !== 0) {
          setSituacoesFiltradas(
            situacoes.map((situacao: any) => ({
              name: situacao.name,
              value: situacao.value,
              quantidade: novasCestasAbandonadasFiltradas.filter(cesta => cesta.situacao === situacao.value).length ?? 0
            }))
          );
          setCestasAbandonadasFiltradas(novasCestasAbandonadasFiltradas);
          setTotalPages(
            Math.ceil(
              novasCestasAbandonadasFiltradas[situacaoAtiva]
                ? Math.ceil(
                    novasCestasAbandonadasFiltradas[situacaoAtiva].length / itensPorPagina
                  )
                : 0
            )
          );
        } else {
          setSituacoesFiltradas(
            situacoes.map((situacao: any) => ({
              name: situacao.name,
              value: situacao.value,
              quantidade: 0,
            }))
          );
          setCestasAbandonadasFiltradas([]);
          setTotalPages(0);
        }

      if (novasCestasAbandonadasFiltradas.length !== 0) {
        setCestasAbandonadasFiltradas(novasCestasAbandonadasFiltradas);
        setTotalPages(
          Math.ceil(novasCestasAbandonadasFiltradas.length / itensPorPagina) ||
            0
        );
      } else {
        setCestasAbandonadasFiltradas([]);
        setTotalPages(0);
      }

      setCurrentPage(1);
      setLoadingSkeleton(false);
    }
  }, [filtroPesquisa, cestasAbandonadas]);

  useEffect(() => {
    const situacaoSalva = localStorage.getItem("situacao");
    if (situacaoSalva) {
      setSituacaoAtiva(situacaoSalva);
    }
  }, []);

  useEffect(() => {
    init(
      dataInicio,
      dataFim,
      filtroUsuario,
      filtroCatalogo,
      filtroClientesIdentificados,
      camposAtivos
    );
  }, []);
  
  
  if (loading) {
    return (
      <>
        <CestasImpressao cestas={cestasImpressao} ref={impressaoRef} />
        <LoadingComFundo mensagem="Imprimindo cestas..." position="fixed" />
      </>
    );
  }

  return (
    <>
      <ContainerCestasAbandonadas theme={theme}>
        <div className="filtros-container">
          <div className="filtros">
            <div className="filtroPesquisa">
              <Input
                type={"text"}
                value={filtroPesquisaAux}
                icone="icons/search.svg"
                placeholder={
                  "Pesquisar por cliente, email, telefone ou catálogo"
                }
                onChange={(newValue) => {
                  newValue === filtroPesquisa
                    ? setLoadingSkeleton(false)
                    : setLoadingSkeleton(true);
                  setFiltroPesquisaAux(newValue);
                }}
                onDebounce={(newValue) => setFiltroPesquisa(newValue)}
                debounceTime={500}
              ></Input>
            </div>
            <div className="filtro">
              <Filtros
                onAplicar={() => {
                  onAplicarFiltro(
                    filtroUsuarioAux,
                    filtroCatalogoAux,
                    filtroClientesIdentificadosAux
                  );
                }}
                onCancelar={() => {
                  setFiltroUsuarioAux(filtroUsuario);
                  setFiltroCatalogoAux(filtroCatalogo);
                  setFiltroClientesIdentificadosAux(
                    filtroClientesIdentificados
                  );
                }}
              >
                <div className="containerSelects">
                  <Select
                    options={optionsUsuarios}
                    value={filtroUsuarioAux}
                    label="Usuário"
                    placeholder="Todos os usuários"
                    onChange={(newValue) => setFiltroUsuarioAux(newValue)}
                  ></Select>
                  <Select
                    options={optionsCatalogos}
                    value={filtroCatalogoAux}
                    label="Catálogo"
                    placeholder="Todos os catálogos"
                    onChange={(newValue) => setFiltroCatalogoAux(newValue)}
                  ></Select>
                  <Select
                    options={[
                      { name: "Clientes identificados", value: "true" },
                      { name: "Clientes não identificados", value: "false" },
                    ]}
                    value={filtroClientesIdentificadosAux}
                    label="Cestas"
                    placeholder="Clientes identificados"
                    onChange={(newValue) =>
                      setFiltroClientesIdentificadosAux(newValue)
                    }
                  ></Select>
                </div>
              </Filtros>
            </div>
            <div className="filtro-data">
              <FiltrosData
                onAplicar={(newValue) => filtrarData(newValue)}
                value={{
                  inicio: dataInicio,
                  fim: dataFim,
                  placeholder: "Últimos 30 dias",
                }}
              ></FiltrosData>
            </div>
          </div>
          <div className="inf-visiveis-container">
            <div className="selectContainer hideDesktop">
                <SelectPedidos
                  options={situacoesFiltradas || situacoes}
                  placeholder="Situação"
                  value={situacaoAtiva}
                  cores={cores}
                  onChange={(newValue) => {
                    handleChangeSituacao(newValue, cestasAbandonadas);
                  }}
                ></SelectPedidos>
              </div>
            <label
              className="inf-visiveis"
              onClick={() => setAsideMenu(!asideMenu)}
            >
              <label className="texto">Configurar informações visíveis</label>
              <SlidersSVG />
            </label>
          </div>
          <AsideMenu
            isOpen={asideMenu}
            setIsOpen={setAsideMenu}
            titulo="Minhas Sacolas Abandonadas /"
            subtitulo="Informações visíveis"
            disabled={camposAtivos.length === 0}
            onAplicar={handleAplicarCampos}
            onCancelar={() => {
              setCamposAtivos(camposAtivosAux);
            }}
            onOpen={() => {
              setCamposAtivosAux(copy(camposAtivos));
            }}
          >
            <div className="container-toggles">
              <div className="texto">
                Selecione abaixo quais colunas <br /> deseja que estejam
                visíveis
              </div>
              <div className="campos scrollBonito">
              {camposPersonalizados.map(
                (
                  campo: {
                    nome: string | (() => string);
                    campo: camposListagem;
                  },
                  index: number
                ) => {
                    return (
                      <div key={index}>
                        <label className="campo">
                          <Toggle
                            value={camposAtivos.includes(campo.campo)}
                            onChange={(newValue) => {
                              if (newValue) {
                                setCamposAtivos([...camposAtivos, campo.campo]);
                              } else {
                                setCamposAtivos(
                                  camposAtivos.filter(
                                    (campoAtivo) => campoAtivo !== campo.campo
                                  )
                                );
                              }
                            }}
                          />
                          {typeof campo.nome === "function"
                            ? campo.nome()
                            : campo.nome}
                        </label>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </AsideMenu>
        </div>
        {!loadingSkeleton && (
          <div className="containerSituacoes hideMobile">
          <div className="situacoes">
            {situacoesFiltradas !== undefined
              ? situacoesFiltradas.map((situacao: any, index: number) => {
                  return (
                    <>
                      <div key={index} className="containerSituacao">
                        <div
                          className={`situacao ${
                            situacao.value === situacaoAtiva && "ativa"
                          }`}
                          onClick={() => handleChangeSituacao(situacao.value, cestasAbandonadas)}
                        >
                          <div className="nome">{situacao.name}</div>
                          <div className="quantidade">
                          {situacao && situacao.quantidade > 0 ? situacao.quantidade : " "}
                          </div>
                        </div>
                        {situacao.value === situacaoAtiva && (
                          <div
                            className="linha"
                            style={{ background: cores[situacaoAtiva] }}
                          ></div>
                        )}
                      </div>
                    </>
                  );
                })
              : situacoes.map((situacao: any, index: number) => {
                  return (
                    <>
                      <div key={index} className="containerSituacao">
                        <div
                          className={`situacao ${
                            situacao.value === situacaoAtiva && "ativa"
                          }`}
                          onClick={() => handleChangeSituacao(situacao.value, cestasAbandonadas)}
                        >
                          <div className="nome">{situacao.name}</div>
                          <div className="quantidade">
                            {situacao && situacao.quantidade > 0 ? situacao.quantidade : " "}
                          </div>
                        </div>
                        {situacaoAtiva === situacao.value && (
                          <div
                            className="linha"
                            style={{ background: cores[situacaoAtiva] }}
                          ></div>
                        )}
                      </div>
                    </>
                  );
                })}
          </div>
        </div>
        
        )}
        <div className="containerTable">
          {loadingSkeleton ? (
            <SkeletonTableCestas />
          ) : (
            <Table
            className="table"
            button={true}
            page={currentPage}
            itensPorPagina={itensPorPagina}
            columns={tableColumns}
            mostrarTotais={false}
            sortable={true}
            selectable={true}
            data={
              (cestasAbandonadasFiltradas !== undefined
                ? cestasAbandonadasFiltradas
                : cestasAbandonadas) || []
            }
            statusColor={cores[situacaoAtiva]}
            onItemSelected={(item) => {
              setcestasSelecionados(item);
            }}
            onButtonClicked={(item) => handleDetalhesCesta(item)}
            onDoubleClicked={(item) => handleDetalhesCesta(item)}
            />
          )}
        </div>
        <FooterPaginas animation={true}>
          <div className="paginacao-table">
            <div className="container-paginacao-table">
              <div className="flex-container">
                {!cestasSelecionados.length ? (
                  <span>
                    <Paginacao
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePaginacao}
                    />
                  </span>
                ) : (
                  <>
                    <span>
                      {cestasSelecionados.length}{" "}
                      {cestasSelecionados.length > 1
                        ? "selecionados"
                        : "selecionado"}
                    </span>
                    <span className="changeSituacao">
                      <div className="flexItens">
                        {situacaoAtiva && (
                          <>
                            <span>Alterar situação para:</span>
                            <span className="select">
                              <SelectPedidos
                                options={
                                  situacaoAtiva === situacaoSelecionada 
                                  ? situacoes 
                                  : situacoes.filter((situacao) => situacao.name)
                                }
                                value={situacaoAtiva}
                                onChange={handleMoverCestas}
                                mostrarQuantidade={false}
                                openUp={true}
                                cores={cores}
                              />
                            </span>
                          </>
                        )}
                        <Button
                          title="Imprimir cestas"
                          className="botao"
                          onClick={() => handleImprimirCestas()}
                        />
                      </div>
                    </span>
                  </>
                )}
                <span className="valor">
                  {cestasAbandonadas && cestasAbandonadas.length > 0 && (
                    <div className="valor-flex">
                      <span className="texto">Valor total:</span>
                      <span>
                        {cestasAbandonadas
                          .reduce((acumulador: number, cesta: any) => {
                            return acumulador + (Number.parseFloat(cesta.valor_total) || 0);
                          }, 0)
                          .toFixed(2)
                          .replace(".", ",") || ""}
                      </span>
                    </div>
                  )}
                  {cestasSelecionados && cestasSelecionados.length > 0 && (
                    <div className="valor-flex">
                      <span className="texto">Selecionado:</span>
                      <span>
                        {cestasSelecionados
                          .reduce((acumulador: number, cesta: any) => {
                            return acumulador + (Number.parseFloat(cesta.valor_total) || 0);
                          }, 0)
                          .toFixed(2)
                          .replace(".", ",") || ""}
                      </span>
                    </div>
                  )}
                </span>
              </div>
            </div>
          </div>
        </FooterPaginas>
      </ContainerCestasAbandonadas>
    </>
  );
};

export default CestasAbandonadas;
